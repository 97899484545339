.switch {
  display: inline-block;
  height: 23px;
  position: relative;
  width: 40px;
  position: relative;
  top: 6px;
  margin-right: 5px;
  margin-left: 25px;
}
.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s;
}
.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 15px;
  left: 4px;
  position: absolute;
  transition: 0.2s;
  width: 15px;
}
input:checked + .slider {
  background-color: #5143bd;
}
input:checked + .slider:before {
  transform: translateX(17px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
