.react-header {
  height: 50px;
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  font-family: futura-pt, sans-serif;
  border-bottom: 1px solid #e7e7e7;
  z-index: 14;
}

.react-header .header-nav {
  padding: 5px 15px;
}

.react-header .header-nav .left-nav {
  float: left;
  width: 50%;
  position:relative;
  z-index: 2
}
.react-header .header-nav .left-nav .menu-item {
  display: inline-block;
  margin-left: 35px;
  font-size: 14px;
  cursor: pointer;
}

.react-header .header-nav .right-nav {
  float: right;
  width: 50%;
  text-align: right;
  position:relative;
  z-index: 2
}
.react-header .header-nav .right-nav .menu-item {
  display: inline-block;
  margin-left: 35px;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 576px) {
  .react-header .header-nav .right-nav {
    position: relative;
    top: 50%;
    right: 0;
    transform: translateY(30%);
  }
}
@media (max-width: 768px) {
  .react-header .header-nav .left-nav .menu-item {
    display: none;
  }
}

@media (max-width: 900px) {
  .react-header .header-nav {
    padding: 4px 0;
  }
  .react-header .header-nav .right-nav .menu-item {
    display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.codehealth-font-family {
  font-family: "Montserrat", sans-serif;
}
.sm {
  font-size: 12px;
}

.menu-anchor {
  color: #333;
  text-decoration: none;
}
.menu-anchor:hover {
  text-decoration: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
